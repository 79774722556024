import * as React from 'react';
import RecipeCard from '../cards/RecipeCard';
import { RecipeModel } from '../../models/RecipeModel';
import { StoryblokModel } from '../../models/StoryblokModel';
import Routes from '../../constants/Routes';

interface Props {
    src : string,
    dimensions : string,
    smartCropping : boolean,
    alt: string
    _className: string
}

//Dimensions have to be defined as string f.ex. 1920x1080 or 1080x1080
const StoryblokImage: React.FunctionComponent<Props> = ({src, dimensions, smartCropping, alt, _className}) => {
    
    //To access storyblok image service, the following endpoint has to be used
    const storyblokImageServiceEndpoint = "https://img2.storyblok.com/" 
    
    //Regex to find Everything after https://a.storyblok.com/, this will be needed for URL reconstruction
    let re = new RegExp(/https:\/\/a.storyblok.com(.*)/)
    let match = re.exec(src);

    if(!match ){
      return null
    }

    //Reconstructing URL with dimensions and smart cropping
    if(smartCropping == true) {
      return (
        <img src={storyblokImageServiceEndpoint + dimensions + "/smart/" + match[1]} alt={alt} className={_className} />
      )      
    }
    else{
        return(
            <img src={storyblokImageServiceEndpoint + dimensions + match[1]} alt={alt} className={_className} />
        )
    }
}

export default StoryblokImage;