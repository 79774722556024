import * as React from 'react';
import Container from '../layout/Container';
import SendYourRecipesImage from '../images/calltoaction/SendYourRecipesImage';
import InternalButton from '../buttons/InternalButton';
import Routes from '../../constants/Routes';

const SendYourRecipes: React.FunctionComponent = () => {
    return (
        <Container className="mt-16">
            <div className="grid grid-cols-1 md:grid-cols-2 mt-8">
              <div className="light-theme-background rounded-l-md p-8 vertical-center">
                <div>
                        <h3 className="mb-4">Få din opskrift på vegetar.dk</h3>
                        <hr className="w-48 mb-4 border-2" />
                        <p className="text-sm mb-6">
                            Vi leder altid efter nye opskrifter.
                            Send os din bedste vegetariske opskrift og hør fra os inden få dage.
                            Så har du god mulighed for at komme på siden.
                        </p>
                        <InternalButton link={Routes.KONTAKT} />
                </div>
              </div>
              <div>
                    <SendYourRecipesImage />
              </div>
            </div>
        </Container>
    );
}

export default SendYourRecipes;