import * as React from 'react';
import { FiMail } from 'react-icons/fi';
import { Link } from 'gatsby';

interface Props {    
    link : string,
}

const InternalButton: React.FunctionComponent<Props> = ({ link }) => {
    return (
        <Link to={link}>
            <button className="internal-button button-text py-2 px-4 rounded inline-flex items-center">
                <FiMail
                    size={20}
                    className="pr-1 mr-1"
                />
                <span>Kontakt os</span>
            </button>
        </Link>
    );
}

export default InternalButton;