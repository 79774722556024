import React from "react"
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"

const OrganizationStructuredData: React.FunctionComponent = () => {

    const { site } = useStaticQuery<any>(
        graphql`
          query siteMetadataQuery{
            site {
                siteMetadata {
                    title
                    siteUrl
                    logoUrl,
                    facebookPage,
                    instagramPage,
                    twitterPage
                    linkedinPage
                }
            }
          }
        `
    )

    const { 
        title, 
        siteUrl, 
        logoUrl,
        facebookPage,
        instagramPage,
        twitterPage,
        linkedinPage
    } = site.siteMetadata;

    const organizationSchema = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": siteUrl,
        "name": title,
        "logo": logoUrl,
        "sameAs" : [
            facebookPage,
            instagramPage,
            twitterPage,
            linkedinPage
        ]
    }

    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(organizationSchema)}
            </script>
        </Helmet>
    )
}

export default OrganizationStructuredData;