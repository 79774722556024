import React from 'react';

interface ComponentProps {
  children: any;
  className?: string;
}

const LightThemeContainer: React.FunctionComponent<ComponentProps> = ({
  children,
  className,
}) => (
  <div className={`light-theme-background pt-8 pb-12 theme-border-bottom theme-border-top ${className}`}>
    {children}
  </div>
);

LightThemeContainer.defaultProps = {
  className: ``,
} as Partial<ComponentProps>;

export default LightThemeContainer;
