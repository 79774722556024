import React from "react"
import SmallContainer from "../layout/SmallContainer"
import LightThemeContainer from "../layout/LightThemeContainer"
import SendYourRecipes from "../calltoaction/SendYourRecipes"

const ShortIntroduction: React.FunctionComponent = () => (
  <>
    <LightThemeContainer>
      <SmallContainer>
        <h2 className="mb-6">Få dine opskrifter på Vegetar.dk</h2>
        <hr />
        <h4>Vi promoverer dig</h4>
        <p className="text-base">
          Her på siden sætter vi stor fokus på de enkelte kokke. Du vil derfor
          blive eksponeret for mange besøgende, som kan følge med i dine
          opskrifter. Vi vil desuden altid linke til dine relevante medier såsom
          Instagram, hvis dette ønskes. På denne måde kan du skaffe en masse nye
          følgere og øge din egen eksponering.
        </p>
        <hr />
        <h4>Vi betaler dig for opskrifter</h4>
        <p className="text-base">
          Vi ved godt, at det tager lang tid at lave gode opskrifter. Derfor vil
          vi altid gerne honorere arbejdet med nye opskrifter. Vi betaler som
          regel per opskrift, men det kan ændres ved længerevarende samarbejde.
        </p>
        <hr />
        <h4>Gode billeder og høj kvalitet</h4>
        <p className="text-base">
          Billeder af opskrifter er essentielle og er med til at gøre folk
          interesserede i opskrifterne. Det forudsætter derfor, at kvaliteten af
          billederne er høj og beskrivelser er lette at følge. Vi tjekker altid
          kvaliteten af opskrifterne, så vi kan bibeholde den gode oplevelse for
          brugerne.
        </p>
      </SmallContainer>
    </LightThemeContainer>
    <SendYourRecipes />
  </>
)

export default ShortIntroduction
