import React, { useState, useEffect } from 'react';
import { PageProps, graphql } from 'gatsby';

import Container from '../components/layout/Container';
import Layout from '../components/layout/Layout';
import MetaData from '../components/seo/MetaData';
import ShortIntroduction from '../components/chefspage/ShortIntroduction';
import OurChefsImage from '../components/images/OurChefsImage';
import OrganizationStructuredData from '../components/seo/OrganizationStructuredData';
import { AuthorModel } from '../models/AuthorModel';
import Slider from "react-slick";
import variables from "../constants/Variables";
// Import css files
import StoryblokImage from '../components/images/StoryblokImage';

const Authors: React.FunctionComponent<PageProps> = ({data}) => {

  const [loading, setLoading] = useState<Boolean>(true)

  const shuffle = (array : Array<any>) => {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }
  
  const [authors, setAuthors] = useState<Array<AuthorModel>>(data.allStoryblokEntry.edges.map(edge => JSON.parse(edge.node.content)))
  
  useEffect(() => {
    setAuthors(shuffle(authors))
    setLoading(false)
  })

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true
  };
  
  return (
    <Layout>
      <MetaData title="Kokke" />
      <OrganizationStructuredData />
      <div className="bg-white">
        <Container className="pt-4 mb-16">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="pr-4 md:pr-8 pt-2 md:pt-16">
            <h1>Mød vores kokke</h1>
            <hr className="w-48 mb-2 border-2" />
            <p className="text-base">
              Vi arbejder sammen med en lang række kokke, som alle bidrager til opskriftsuniverset.
              Her på siden kan du læse mere omkring deres arbejde og deres opskrifter.
              Desuden får du mere information omkring hvordan du bliver en del af vegetar.dk.
              Her får du mulighed for at tjene penge samtidig med at få øget eksponering.
            </p>
          </div>
          <div className="md:mt-8 md:ml-8 md:content-center pt-8">
            <Slider {...settings}>
                  {loading === false ? authors.map(author => {
                      return(
                        <div key={author.author_name}>
                          <StoryblokImage src={author.author_image[0].filename} alt={author.author_image[0].alt} dimensions={"1200x1600"} smartCropping={true} _className={"w-full rounded-lg"}/>
                            <p className="text-center font-semibold" style={{fontSize:variables.fontSizeSubtitle, fontFamily:variables.fontFamilyText}}>{author.author_name}</p>
                            <p className="text-center italic" style={{fontSize:14, fontFamily:variables.fontFamilyText}}>{author.author_description[0].description_name}</p>
                        </div>
                      )
                  }) : <p>Loading...</p>}
            </Slider>
          </div>
        </div>
        </Container>
        <ShortIntroduction />
      </div>
    </Layout>
  );
};

export const query = graphql`
query QueryAllAuthors{
  allStoryblokEntry (filter: {field_component: {eq: "AuthorPage"}}){
            edges {
              node {
                id
                name
                created_at
                uuid
                slug
                full_slug
                content
                field_author_name_string
              }
            }
          }
}`


export default Authors;